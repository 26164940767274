<template>
    <div>
        <!-- Change provider id modal -->
        <generic-confirm-modal
            ref="modal-provider-id"
            :title="providerId ? 'Modification de l\'id du provider' : 'Création de l\'id du provider'"
            cancel-title="Annuler"
            :ok-title="providerId ? 'Modifier' : 'Créer'"
            @on-accept="changeProviderId"
            @on-cancel="$refs['modal-provider-id'].close()"
        >
            <b-input id="input-provider-id" v-model="providerId"></b-input>
        </generic-confirm-modal>
        <b-dropdown right id="dropdown-right" variant="primary" text="Actions">
            <b-dropdown-item v-if="info.status <= 1" @click="bannerDownload(info)">
                <b-button variant="primary" class="btn-icon mr-1">
                    <feather-icon icon="DownloadIcon" />
                </b-button>
                Télécharger la bannière</b-dropdown-item
            >
            <b-dropdown-item
                v-if="info.status <= 1"
                @click="
                    addThemeToCampaign(
                        info.start_date,
                        info.end_date,
                        info.campaign_code
                        // info.details.point_text[0]
                    )
                "
            >
                <b-button variant="primary" class="btn-icon mr-1">
                    <feather-icon icon="PlusCircleIcon" />
                </b-button>
                Télécharger une autre bannière</b-dropdown-item
            >
            <b-dropdown-item v-if="info.status === 0 || info.status === 1" @click="providerCampaignCreation(info.campaign_code)">
                <b-button variant="primary" class="btn-icon mr-1">
                    <feather-icon icon="PlusIcon" />
                </b-button>
                Créer / modifier la campagne provider</b-dropdown-item
            >
            <b-dropdown-item v-if="info.status >= 7" @click="downloadReport(info)">
                <b-button variant="primary" class="btn-icon mr-1">
                    <feather-icon icon="DownloadIcon" />
                </b-button>
                Télécharger le bilan de campagne</b-dropdown-item
            >
            <b-dropdown-item v-if="info.status === 10" @click="onOpenAlert('primary', info.campaign_code)">
                <b-button variant="primary" class="btn-icon mr-1">
                    <feather-icon icon="CreditCardIcon" />
                </b-button>
                {{ data.item.left_to_pay <= 0 ? 'Envoi du paiement' : 'Archiver' }}
            </b-dropdown-item>
            <b-dropdown-item v-if="info.status >= 7 || info.status === 10 || info.status === 20" @click="tradingRoute(info.campaign_code)">
                <b-button variant="primary" class="btn-icon mr-1">
                    <feather-icon icon="Edit2Icon" />
                </b-button>
                Éditer</b-dropdown-item
            >
        </b-dropdown>
        <update-date-modal ref="update-date-modal" @on-update-date-campaign="updateDateCampaign"></update-date-modal>
        <Create-provider-campaign-modal ref="create-provider-campaign-modal"></Create-provider-campaign-modal>
    </div>
</template>
<script>
    import http from '@/helpers/http';
    import FileSaver from 'file-saver';
    import UpdateDateModal from '../UpdateDateModal/update-date-modal.vue';
    import CreateProviderCampaignModal from '../../LinkCampaigns/create-provider-campaign-modal.vue';
    import { mapGetters } from 'vuex';

    export default {
        components: {
            UpdateDateModal,
            CreateProviderCampaignModal,
        },
        props: {
            info: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                providerId: null,
            };
        },
        methods: {
            startDateCampaign(startDate, endDateRaw, code) {
                this.$refs['update-date-modal'].open(startDate, endDateRaw, code);
            },

            updateDateCampaign(campaignsDatesToUpdate) {
                this.$root.toggleGlobalLoading(true);
                var data = {
                    campaigns: campaignsDatesToUpdate,
                };

                this.$store
                    .dispatch('app/updateCampaignDate', data)
                    .then((res) => {
                        this.$emit('update');

                        this.$refs['update-date-modal'].close();
                        this.$toast({
                            component: 'toast',
                            props: {
                                title: 'Date(s) modifiée avec succès',
                                icon: 'CheckCircleIcon',
                                variant: 'success',
                            },
                        });
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast({
                            component: 'toast',
                            props: {
                                title: 'Erreur lors de la modification des dates',
                                icon: 'AlertCircleIcon',
                                variant: 'danger',
                            },
                        });
                    })
                    .finally(() => {
                        this.$root.toggleGlobalLoading(false);
                    });
            },
            downloadReport(data) {
                //
                // CODE A ADAPTER AVEC LA BONNE DATA
                //
                const campaignCode = data.campaign_code;

                const pointText = data.details.point_text[0].replaceAll(' ', '-');
                const startDate = data.start_date.replaceAll('/', '-');
                const brand = campaignCode.split('_')[1].toLowerCase();

                this.$root.toggleGlobalLoading(true);
                http.getPDF(this.$store.state.app.apiCampaignUrl + '/api/v1/' + brand + '/campaign/' + campaignCode + '/metrics', localStorage.getItem('accessToken'))
                    .then((res) => {
                        this.$root.toggleGlobalLoading(false);
                        if (res[0].data.byteLength > 0) {
                            const blob = new Blob([res[0].data], {
                                type: res[0].headers['content-type'],
                            });

                            FileSaver.saveAs(blob, pointText + '_' + startDate + '_bilan.pdf');
                            this.$toast({
                                component: 'toast',
                                props: {
                                    title: 'Le rapport a été téléchargé',
                                    icon: 'smile',
                                    variant: 'success',
                                },
                            });
                        } else {
                            this.$toast({
                                component: 'toast',
                                props: {
                                    title: 'Pas de rapport à télécharger',
                                    icon: 'x-circle',
                                    variant: 'danger',
                                },
                            });
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        this.$toast({
                            component: 'toast',
                            props: {
                                title: 'Erreur lors de la génération du rapport',
                                icon: 'x-circle',
                                variant: 'danger',
                            },
                        });
                    });
            },
            tradingRoute(code) {
                this.$router.push({
                    name: 'trading',
                    params: {
                        campaign_code: code,
                    },
                });
            },
            bannerDownload(camp) {
                // TODO
                console.warn('bannerDownload A TESTER SUR CAMPAIGN AVEC STATUS 1 OU 0');
                this.$root.toggleGlobalLoading(true);
                var data = {
                    code: camp.campaign_code,
                    pointCode: camp.details.point_text[0],
                    theme: '',
                };

                this.$store.dispatch('app/downloadBanner', data).then((res) => {
                    this.$root.toggleGlobalLoading(false);
                    if (res.data.length > 0) {
                        for (const url of res.data) {
                            const fileLink = document.createElement('a');
                            fileLink.href = url;
                            fileLink.target = '_blank';
                            fileLink.setAttribute('download', camp.pointText);
                            document.body.appendChild(fileLink);
                            fileLink.click();
                        }

                        this.$toast({
                            component: 'toast',
                            props: {
                                title: 'La bannière a été téléchargée',
                                icon: 'smile',
                                variant: 'success',
                            },
                        });
                    } else {
                        this.$toast({
                            component: 'toast',
                            props: {
                                title: 'Pas de bannière à télécharger',
                                icon: 'x-circle',
                                variant: 'danger',
                            },
                        });
                    }
                });
            },
            addThemeToCampaign(startDate, endDateRaw, code) {
                this.$refs['add-campaign-modal'].open(startDate, endDateRaw, code);
            },
            providerCampaignCreation(campaignCode) {
                this.$refs['create-provider-campaign-modal'].open(campaignCode);
            },
            changeProviderId() {
                this.$root.toggleGlobalLoading(true);
                this.$store
                    .dispatch('app/changeProviderId', {
                        code: this.currentCampaignCode,
                        id: this.providerId,
                    })
                    .then(() => {
                        this.$toast(this.successToast('ID mis à jour'));
                        this.$refs['modal-provider-id'].close();
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast(this.errorToast("Erreur lors de la mise à jour de l'id"));
                    })
                    .finally(() => {
                        this.$root.toggleGlobalLoading(false);
                    });
            },
            onOpenAlert(color, campaignCode) {
                switch (color) {
                    case 'success':
                        this.openAlert('Êtes-vous sûr de vouloir valider la campagne ?', this.acceptAlert, campaignCode, color);
                        break;
                    case 'primary':
                        this.openAlert('Êtes-vous sûr de vouloir lancer le paiement / archiver de la campagne ?', this.payAlert, campaignCode, color);
                        break;
                    case 'danger':
                        this.openAlert('Êtes-vous sûr de vouloir refuser la campagne ?', this.refuseAlert, campaignCode, color);
                        break;
                }
            },
            openAlert(msg, callback, campaignCode, color) {
                this.$bvModal
                    .msgBoxConfirm(msg, {
                        title: 'Please Confirm',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: color,
                        okTitle: 'YES',
                        cancelTitle: 'NO',
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true,
                    })
                    .then((confirmed) => {
                        if (confirmed) callback(campaignCode);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },

            acceptAlert(code) {
                this.$root.toggleGlobalLoading(true);
                var data = {
                    status: 'accept',
                };
                this.$store
                    .dispatch('app/updateCampaign', {
                        code,
                        data,
                    })
                    .then(() => {
                        this.$toast(this.successToast('La campagne à été validée'));
                        this.$emit('on-refresh');
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast(this.errorToast('Erreur lors de la validation de la campagne'));
                    })
                    .finally(() => {
                        this.$root.toggleGlobalLoading(false);
                    });
            },
            payAlert(code) {
                this.$root.toggleGlobalLoading(true);
                var data = {
                    status: 'pay',
                };
                this.$store
                    .dispatch('app/updateCampaign', {
                        code,
                        data,
                    })
                    .then(() => {
                        this.$toast(this.successToast('La campagne à été payée / archivée'));
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast(this.errorToast('Erreur lors du paiement de la campagne'));
                    })
                    .finally(() => {
                        this.$root.toggleGlobalLoading(false);
                    });
            },
            refuseAlert(code) {
                this.$root.toggleGlobalLoading(true);
                var data = {
                    status: 'reject',
                };
                this.$store
                    .dispatch('app/updateCampaign', {
                        code,
                        data,
                    })
                    .then(() => {
                        this.$toast(this.successToast('La campagne à bien été refusée'));
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast(this.errorToast('Erreur lors du rejet de la campagne'));
                    })
                    .finally(() => {
                        this.$root.toggleGlobalLoading(false);
                    });
            },
        },
        computed: {
            ...mapGetters({
                formatToDate: 'app/formatToDate',
                formatToDashDate: 'app/formatToDashDate',
                successToast: 'app/successToast',
                errorToast: 'app/errorToast',
            }),
        },
    };
</script>
