<template>
    <div class="badge" :class="badgeInfo.class">{{ badgeInfo.name }} {{ statusInfo }}</div>
</template>

<script>
    export default {
        props: {
            status: {
                type: Number,
                required: true,
            },
            statusDetail: {
                type: Object,
                required: false,
            },
        },

        computed: {
            statusInfo() {
                if ((this.status === 0 || this.status === 1) && this.statusDetail && this.statusDetail.inProgressChildren > 0) {
                    return `${this.statusDetail.inProgressChildren}/${this.statusDetail.childCount}`;
                }
                return '';
            },

            badgeInfo() {
                if (this.status === 0 && this.statusDetail && this.statusDetail.inProgressChildren > 0) {
                    return {
                        name: 'En cours',
                        class: 'running',
                    };
                }

                switch (this.status) {
                    case 0:
                        return {
                            name: 'Non commencé',
                            class: 'not-started',
                        };
                    case 1:
                        return {
                            name: 'En cours',
                            class: 'running',
                        };
                    case 5:
                        return {
                            name: 'En attente remontée top ten',
                            class: 'top-ten',
                        };
                    case 7:
                        return {
                            name: 'Validation traders',
                            class: 'trader-validation',
                        };
                    case 8:
                        return {
                            name: 'Validation CSM',
                            class: 'csm-validation',
                        };
                    case 9:
                        return {
                            name: 'Validation direction',
                            class: 'dir-validation',
                        };
                    case 10:
                        return {
                            name: 'En attente de paiement',
                            class: 'wait-payment',
                        };
                    case 20:
                        return {
                            name: 'Payé',
                            class: 'paid',
                        };
                    case 30:
                        return {
                            name: 'Archivé',
                            class: 'archived',
                        };
                    case 99:
                        return {
                            name: 'Annulé',
                            class: 'cancelled',
                        };

                    default:
                        return {
                            name: 'En attente de Paiement',
                            class: 'wait-payment',
                        };
                }
            },
        },
    };
</script>

<style scoped lang="scss">
    @import '~@core/scss/base/bootstrap-extended/include';
    @import 'src/@core/scss/base/bootstrap-extended/_variables.scss';

    .badge-client {
        background-color: $dropdown-link-hover-bg;
    }

    .badge {
        background: rgba(40, 199, 111, 0.16);
        color: $success;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px 10px;
        border-radius: 4px;
        margin-right: 24px;
        height: 24px;
        margin-left: 8px;

        &.wait-payment {
            background: rgba(234, 84, 85, 0.16);
            color: $red;
        }

        &.not-started {
            background: rgba(115, 103, 240, 0.16);
            color: $primary;
        }

        &.running {
            background: rgba(40, 199, 111, 0.16);
            color: $green;
        }
        &.top-ten {
            background: rgba(0, 207, 232, 0.16);
            color: $blue;
        }

        &.trader-validation,
        &.csm-validation,
        &.dir-validation {
            background: rgba(255, 159, 67, 0.16);
            color: $orange;
        }

        &.paid,
        &.cancelled,
        &.archived {
            background: rgba(75, 75, 75, 0.16);
            color: $dark;
        }
    }
</style>
