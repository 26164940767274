<template>
    <div class="">
        <!-- Change provider id modal -->
        <generic-confirm-modal
            ref="modal-provider-id"
            :title="data.providerId ? 'Modification de l\'id du provider' : 'Création de l\'id du provider'"
            cancel-title="Annuler"
            :ok-title="data.providerId ? 'Modifier' : 'Créer'"
            @on-accept="changeProviderId"
            @on-cancel="$refs['modal-provider-id'].close()"
        >
            <b-input id="input-provider-id" v-model="data.providerId"></b-input>
        </generic-confirm-modal>

        <generic-confirm-modal ref="modal-media-link" title="Lier la campagne" cancel-title="Annuler" ok-title="Lier" @on-accept="onSubmit" @on-cancel="$refs['modal-media-link'].close()">
            <b-form @submit.stop.prevent="">
                <b-form-group :label="`Marque`" label-for="select-brand">
                    <b-input id="select-brand" v-model="brand" disabled></b-input>
                </b-form-group>
                <b-form-group :label="`Point de vente`" label-for="select-pos">
                    <b-input id="select-pos" v-model="salePoint" disabled></b-input>
                </b-form-group>
                <b-form-group :label="`Code de point de vente`" label-for="select-pos-code">
                    <b-input id="select-pos-code" v-model="salePointCode" disabled></b-input>
                </b-form-group>
                <b-form-group :label="`Média categorie`" label-for="select-media">
                    <b-input id="select-media" v-model="media" disabled></b-input>
                </b-form-group>
                <b-form-group label="DSP" label-for="select-dsp">
                    <b-form-select id="select-dsp" v-model="selectedDsp" :options="dspOptions" text-field="name" value-field="value" :state="isValidDsp"></b-form-select>
                    <b-form-invalid-feedback :state="isValidDsp">Un DSP doit être sélectionné</b-form-invalid-feedback>
                </b-form-group>

                <b-form-group label="ID" label-for="select-id">
                    <b-input id="selecte-id" v-model="id" :state="isValidId" />
                    <b-form-invalid-feedback :state="isValidId">Une ID doit être saisie</b-form-invalid-feedback>
                </b-form-group>
            </b-form>
        </generic-confirm-modal>

        <!-- Modal to delete or update child campaign -->
        <b-modal ref="modal-update-campaign-lever" hide-footer title="Supprimer/Remplacer le média">
            <b-form>
                <span>Êtes-vous sûr de vouloir supprimer le média "{{ data.mediaName }}" ? </span>

                <b-form-checkbox class="mt-1" v-model="reassignLever"> Réaffecter le budget d'un levier à un autre </b-form-checkbox>

                <b-form-group label="Choix du levier" label-for="select-new-lever" class="mt-1" v-if="reassignLever">
                    <b-form-select id="select-new-lever" v-model="selected" :options="leversOptions"></b-form-select>
                </b-form-group>

                <div class="row" v-if="reassignLever && selected">
                    <div class="row ml-1" v-if="!isPackage">
                        <b-form-group label="Nouveau objectif" label-for="new-goal" class="mr-sm-2">
                            <b-input type="number" id="new-goal" v-model="newGoal"></b-input>
                        </b-form-group>

                        <b-form-group label="Objectif total" label-for="total-goal">
                            <p class="mt-1">{{ actualGoal }}</p>
                        </b-form-group>
                    </div>
                    <div class="row ml-1" v-else>
                        <b-form-group label="Budget à réaffecter : " label-for="new-budget" class="mr-5">
                            <p class="mt-1">{{ data.price }} €</p>
                        </b-form-group>

                        <b-form-group label="Budget total à réaffecté : " label-for="total-budget" class="ml-5">
                            <p class="mt-1">{{ actualGoal }}</p>
                        </b-form-group>
                    </div>
                </div>
            </b-form>

            <div class="d-flex justify-content-between">
                <b-button class="mt-3" @click="cancelLeverModal()">Annuler</b-button>
                <b-button class="mt-3" variant="primary" :disabled="disabledLeverBtn" @click="updateCampaign()"> Confirmer </b-button>
            </div>
        </b-modal>

        <b-dropdown size="lg" right variant="link" toggle-class="text-decoration-none" class="flex" no-caret>
            <template #button-content>
                <feather-icon class="mr-1" icon="MoreVerticalIcon" size="30" />
            </template>

            <b-dropdown-item v-if="data.status === 0 || data.status < 8" @click="handleClick">
                <b-button variant="primary" class="btn-icon mr-1">
                    <feather-icon icon="RefreshCwIcon" />
                </b-button>
                Changer ID prestataire</b-dropdown-item
            >

            <b-dropdown-item v-if="data.status <= 1 || data.status === 5 || data.status === 7" @click="syncMetrics(data.campaign_code)">
                <b-button variant="primary" class="btn-icon mr-1">
                    <feather-icon icon="RefreshCwIcon" />
                </b-button>
                Synchroniser les metrics</b-dropdown-item
            >
            <b-dropdown-item v-if="data.status <= 1 || data.status === 5 || data.status === 7" @click="forceMetrics(data.campaign_code)">
                <b-button variant="primary" class="btn-icon mr-1">
                    <feather-icon icon="RefreshCwIcon" />
                </b-button>
                Forcer les metrics</b-dropdown-item
            >
            <b-dropdown-item v-if="data.status <= 1 || data.status === 5 || data.status === 7" @click="fillWithZeroMetrics(data.campaign_code)">
                <b-button variant="primary" class="btn-icon mr-1">
                    <feather-icon icon="RefreshCwIcon" />
                </b-button>
                Compléter les metrics avec 0</b-dropdown-item
            >
            <b-dropdown-item v-if="data.status === 7 || data.status === 0" @click="completeTopTen(data.campaign_code)">
                <b-button variant="primary" class="btn-icon mr-1">
                    <feather-icon icon="RefreshCwIcon" />
                </b-button>
                Compléter les top ten</b-dropdown-item
            >

            <b-dropdown-item v-if="data.status === 0 || data.status === 1" @click="$refs['modal-update-campaign-lever'].show()">
                <b-button variant="danger" class="btn-icon mr-1">
                    <feather-icon icon="TrashIcon" />
                </b-button>
                Supprimer / Remplacer</b-dropdown-item
            >
        </b-dropdown>
    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex';
    import axios from 'axios';

    export default {
        props: {
            data: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                selectedCampaign: null,
                brand: null,
                media: null,
                salePoint: null,
                salePointCode: null,
                selectedDsp: '',
                dspOptions: [],
                isValidDsp: null,
                id: '',
                isValidId: null,
                renderErrors: false,
                reassignLever: false,
                selected: null,
                newGoal: 0,
                isPackage: false,
                goal: '',
            };
        },

        computed: {
            ...mapGetters({
                formatToDate: 'app/formatToDate',
                formatToDashDate: 'app/formatToDashDate',
                successToast: 'app/successToast',
                errorToast: 'app/errorToast',
            }),
            disabledLeverBtn() {
                if (this.reassignLever) {
                    return this.isPackage ? !this.selected : !(this.selected && this.newGoal);
                } else {
                    return false;
                }
            },
            leversOptions() {
                return [
                    {
                        label: 'Levier(s) de la campagne : ',
                        options: this.childOptions,
                    },
                    {
                        label: 'Autres leviers : ',
                        options: this.otherOptions,
                    },
                ];
            },
            childOptions() {
                return this.data.otherChildrenCampaign.map((child) => {
                    child.name = this.getMediaMetrics(child.media_support_code).label;
                    return { text: child.name, value: child.media_support_code };
                });
            },
            otherOptions() {
                return this.$store.state.app.mediaMetrics
                    .filter((m) => {
                        let otherMedias = this.getChildrenCampaign(m.media);
                        if (!otherMedias && m.media !== this.data.media) {
                            return true;
                        }
                    })
                    .map((o) => {
                        return { text: o.label, value: o.media };
                    });
            },
            actualGoal() {
                if (this.selected) {
                    let child = this.getChildrenCampaign(this.selected);
                    let mediaMetrics = this.getMediaMetrics(this.selected);
                    let geolink = JSON.parse(mediaMetrics.geolinkDetails);
                    this.isPackage = geolink.isPackage ? true : false;
                    if (child) {
                        this.goal = child.goal;
                        if (geolink.isPackage) {
                            return Number(child.details.unit_price_excluding_taxes) + Number(this.data.price) + ' €';
                        } else {
                            const totalGoal = Number(child.details.quantity) + Number(this.newGoal);
                            return totalGoal + ' ' + this.goal;
                        }
                    } else {
                        if (geolink.isPackage) {
                            return Number(this.data.price) + ' €';
                        } else {
                            this.goal = geolink.goal;
                            return this.newGoal ? Number(this.newGoal) + ' ' + this.goal : '-- ' + this.goal;
                        }
                    }
                }
            },
        },
        methods: {
            ...mapActions('app', ['removeChildCampaign', 'changeChildCampaign', 'transferChildCampaign']),

            cancelLeverModal() {
                this.reassignLever = false;
                this.selected = null;
                this.$refs['modal-update-campaign-lever'].hide();
            },

            getMediaMetrics(mediaCode) {
                return this.$store.state.app.mediaMetrics.find((m) => m.media === mediaCode);
            },

            getChildrenCampaign(mediaCode) {
                return this.data.otherChildrenCampaign.find((c) => c.media_support_code === mediaCode);
            },

            // fonction pour changer un levier de campaign
            async updateCampaign() {
                try {
                    if (!this.reassignLever) {
                        await this.removeChildCampaign({ code: this.data.campaign_code, mediaCode: this.data.media });
                    } else {
                        let child = this.getChildrenCampaign(this.selected);
                        let data = {
                            goal: this.isPackage ? '' : this.actualGoal.split(' ')[0],
                            type_goal: this.isPackage ? '' : this.goal,
                        };
                        let params = {
                            code: this.data.campaign_code,
                            mediaCode: this.data.media,
                            newMediaCode: this.selected,
                        };
                        if (child) {
                            await this.transferChildCampaign({ params, data });
                        } else {
                            await this.changeChildCampaign({ params, data });
                        }
                    }
                    this.$emit('updateChildCampaign');
                    this.$toast(this.successToast('Mise à jour de la campaign réussie'));
                } catch (error) {
                    console.log(error);
                    this.$toast(this.errorToast('Mise à jour de la campaign échouée'));
                }
            },

            changeProviderId() {
                this.$root.toggleGlobalLoading(true);
                this.$store
                    .dispatch('app/changeProviderId', {
                        code: this.data.campaign_code,
                        id: this.data.providerId,
                    })
                    .then(() => {
                        this.$toast(this.successToast('ID mis à jour'));
                        this.$refs['modal-provider-id'].close();
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast(this.errorToast("Erreur lors de la mise à jour de l'id"));
                    })
                    .finally(() => {
                        this.$root.toggleGlobalLoading(false);
                    });
            },
            syncMetrics(code) {
                this.$root.toggleGlobalLoading(true);
                this.$store
                    .dispatch('app/syncMetrics', {
                        code,
                    })
                    .then(() => {
                        this.$toast(this.successToast('Synchronisation des metrics effectuée'));
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast(this.errorToast('Erreur lors de la synchronisation des metrics'));
                    })
                    .finally(() => {
                        this.$root.toggleGlobalLoading(false);
                    });
            },
            forceMetrics(code) {
                this.$root.toggleGlobalLoading(true);
                this.$store
                    .dispatch('app/forceMetrics', {
                        code,
                    })
                    .then(() => {
                        this.$toast(this.successToast('Les metrics ont été forcées'));
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast(this.errorToast('Erreur lors du forcing des metrics'));
                    })
                    .finally(() => {
                        this.$root.toggleGlobalLoading(false);
                    });
            },
            fillWithZeroMetrics(code) {
                this.$root.toggleGlobalLoading(true);
                this.$store
                    .dispatch('app/fillWithZeroMetrics', {
                        code,
                    })
                    .then(() => {
                        this.$toast(this.successToast('Mise à jour des metrics effectuée'));
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast(this.errorToast('Erreur lors de la mise à jour des metrics'));
                    })
                    .finally(() => {
                        this.$root.toggleGlobalLoading(false);
                    });
            },
            completeTopTen(code) {
                this.$root.toggleGlobalLoading(true);
                this.$store
                    .dispatch('app/completeTopTen', {
                        code,
                    })
                    .then(() => {
                        this.$toast(this.successToast('Top ten mis à jour'));
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast(this.errorToast('Erreur lors de la mise à jour des top ten'));
                    })
                    .finally(() => {
                        this.$root.toggleGlobalLoading(false);
                    });
            },
            onSubmit() {
                if (!this.selectedDsp) {
                    this.isValidDsp = false;
                    return;
                }
                if (!this.id) {
                    this.isValidId = false;
                    return;
                }

                this.openAlert();
            },
            openAlert() {
                this.$bvModal
                    .msgBoxConfirm('Êtes-vous sûr de vouloir lier les campagnes ?', {
                        title: 'Confirmer',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'primary',
                        okTitle: 'OUI',
                        cancelTitle: 'NON',
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true,
                    })
                    .then((confirmed) => {
                        if (confirmed) {
                            this.linkCampaign(this.data.campaign_code, this.data.media, this.selectedDsp, this.id);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            linkCampaign(campaign, media, dsp, id) {
                this.$root.toggleGlobalLoading(true);
                let data = {
                    provider: dsp,
                    media,
                    id,
                };
                this.$store
                    .dispatch('app/linkCampaign', {
                        code: campaign,
                        data,
                    })
                    .then((res) => {
                        this.$toast(this.successToast('Les campagnes ont été liées avec succès'));
                    })
                    .catch((e) => {
                        this.$toast(this.errorToast("Les campagnes n'ont pas pu être liées"));
                    })
                    .finally(() => {
                        this.$root.toggleGlobalLoading(false);
                    });
            },

            async handleClick() {
                const dsp = await axios.get(process.env.VUE_APP_APIURL + '/metrics/api/dspProviders/');

                this.dspOptions = dsp.data;

                this.$refs['modal-media-link'].open();
            },
        },

        mounted() {
            this.selectedCampaign = this.data.campaign_code;
            this.brand = this.data.brand.charAt(0).toUpperCase() + this.data.brand.slice(1);
            this.media = this.data.media;
            this.salePoint = this.data.salePoint[0];
            this.salePointCode = this.data.salePointCode[0];
            this.id = this.data.providerId;
            this.selectedDsp = this.data.provider;
        },
        watch: {
            selectedDsp(newValue, oldValue) {
                if (newValue) {
                    this.isValidDsp = true;
                }
            },
            id(newValue, oldValue) {
                if (newValue) {
                    this.isValidId = true;
                }
            },
        },
    };
</script>
